const estaAutenticado = () => {
  const tokenGuardado = localStorage.getItem('token');

  if (tokenGuardado === '' || tokenGuardado === null) {
    return false;
  }
  return true;
};

export default estaAutenticado;
