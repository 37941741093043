import React, { useEffect } from 'react';
import BarraLateral from '../../componentes/visual/BarraLateral';
import BarraSuperior from '../../componentes/visual/BarraSuperior';
import '../../assets/css/styles.css';
import { lerToken } from '../../componentes/nucleo/lerToken';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TabPanel from '../../componentes/visual/TabsMain';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: theme.palette.grey[50],
  },
  containerMainContent: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Main() {
  const classes = useStyles;
  const telaCelular = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    var atoken = lerToken(localStorage.getItem('token'));
    // Id usuário
    // var idUsuario =
    // Atualiza o titulo do documento usando a API do browser
    // Essa função gerencia o evento de envio do form
    axios
      .post(`${process.env.REACT_APP_BASE_URL_API}`, null, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          token: localStorage.getItem('token'),
          funcao: 'retornaDadosUsuario',
          data: atoken[0],
        },
      })
      .then(function (response) {
        console.log('Resposta »', response);
      })
      .catch(function (error) {});
  }, []);

  return (
    <>
      <Box
        display="flex"
        className="debug"
        flexWrap="wrap"
        style={{ backgroundColor: '#fff', height: '100vh' }}
      >
        {!telaCelular && (
          <Box component="div" width="70px">
            <BarraLateral />
          </Box>
        )}
        <Box flexGrow="1" flexWrap="wrap" justifyContent="space-around" className="debug">
          <Box display="flex" flexDirection="column">
            <Box>
              <BarraSuperior />
            </Box>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              <Box flexGrow="1" className={classes.containerMainContent}>
                <TabPanel />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
