import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import MainGrid from '../../paginas/main/MainGrid';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    component: `div`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  estiloAppBar: {
    backgroundColor: '#F4F5F6',
    color: '#000',
    height: '40px',
    boxShadow: 'none',
  },
  estiloTabs: {
    height: '40px',
  },
  iconeFecharPosicao: {
    position: 'absolute',
    top: '-2px',
    right: '2px',
  },
  iconeFechar: {
    color: theme.palette.grey[500],
    fontSize: '12px',
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fecharAba = (event) => {
    alert(event.target.name);
  };

  return (
    <div className={`classes.root`}>
      <AppBar position="static" className={`${classes.estiloAppBar}`}>
        <Tabs
          style={{ maxHeight: '15px', indicator: 'none' }}
          value={value}
          onChange={handleChange}
          aria-label="Tabs da página principal"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={
              <p>
                HOME
                <span className={classes.iconeFecharPosicao} onClick={fecharAba}>
                  <CloseIcon className={classes.iconeFechar} />
                </span>
              </p>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <p>
                Clientes
                <span className={classes.iconeFecharPosicao}>
                  <CloseIcon className={classes.iconeFechar} />
                </span>
              </p>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <p>
                Contas Receber
                <span className={classes.iconeFecharPosicao}>
                  <CloseIcon className={classes.iconeFechar} />
                </span>
              </p>
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <p>
                Vendas
                <span className={classes.iconeFecharPosicao}>
                  <CloseIcon className={classes.iconeFechar} />
                </span>
              </p>
            }
            {...a11yProps(3)}
          />
          <Tab
            label={
              <p>
                Apuração
                <span className={classes.iconeFecharPosicao}>
                  <CloseIcon className={classes.iconeFechar} />
                </span>
              </p>
            }
            {...a11yProps(4)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <MainGrid url={`mainsc.php`} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MainGrid url={`clientessc.php`} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MainGrid url={`recebersc.php`} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <MainGrid url={`dashvendasqtd.php`} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <MainGrid url={`apuracaocimento.php`} />
      </TabPanel>
    </div>
  );
}
