import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from '../../paginas/login/Login';
import Main from '../../paginas/main/Main';
import estaAutenticado from './Auth';
import Cep from './cep';
import '../../assets/css/styles.css';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      estaAutenticado() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

const Rotas = () => {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/main" component={Main} />
      <PrivateRoute path="/cep" component={Cep} />
      <Route
        path="/contato"
        component={() => (
          <div className="p-5 opcoesContato">
            {' '}
            <h1>Contato</h1> <br /> <p>opções de contato aqui</p>
          </div>
        )}
      />
      {/* Se não bater com nenhum endereço anterior */}
      <Route
        component={() => (
          <div className="p-5 error404">
            {' '}
            <h1>404 error</h1> <br />
            <p>Página não encontrada!</p>
          </div>
        )}
      />
      {/* OUTRO EXEXMPLO DE ROTA PROTEGIDA */}
      {/* <Route exact path="/home" render={() => (
        isLoggedIn() ? (
          <Redirect to="/front"/>
        ) : (
          <Home />
        )
      )}/> */}
    </Switch>
  );
};

export default Rotas;
