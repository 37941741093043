import { chktoken } from './chktoken';

export const lerToken = (token) => {
  if (chktoken(token)) {
    var jwt = require('jsonwebtoken');
    var jwtDecodificado = jwt.decode(token);
    if (jwtDecodificado === null) {
      return [];
    }
    var idUsuario = jwtDecodificado[0];
    var empresaCliente = jwtDecodificado[1];
    var bdCliente = jwtDecodificado[2];
    return [idUsuario, empresaCliente, bdCliente];
  } else {
    return [];
  }
};
