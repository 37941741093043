import React from 'react';
import logoPeq from '../../assets/imagens/logoWebgerente.svg';
import avatar from '../../assets/imagens/avatar.jpg';
import iconeMenuFicha from '../../assets/imagens/icones/ficha.svg';
import iconeMenuCaminho from '../../assets/imagens/icones/caminho.svg';
import iconeMenuExclusao from '../../assets/imagens/icones/exclusao.svg';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';

const opcoesMenu = [
  {
    id: '1',
    nome: 'teste 1',
    icone: iconeMenuFicha,
    path: '',
    ordem: '',
  },
  {
    id: '2',
    nome: 'teste 2',
    icone: iconeMenuCaminho,
    path: '',
    ordem: '',
  },
  {
    id: '3',
    nome: 'teste 3',
    icone: iconeMenuExclusao,
    path: '',
    ordem: '',
  },
];

const BarraLateral = () => {
  return (
    <Box display="flex" flexDirection="column" className="barraLateralFixa">
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box paddingTop={1}>
          <img className="logoBarraLateralFixa" src={logoPeq} alt="AxerWeb" title="AxerWeb" />
        </Box>
        <Box paddingTop={3}>
          <Avatar alt="Imagem Padrão" src={avatar} />
        </Box>
        <Box paddingTop={0.2}>
          <p>Usuário</p>
        </Box>
        <Box paddingTop={2}>
          <Box>
            {opcoesMenu ? (
              opcoesMenu.map((opcoes) => (
                <Box display="flex" flexDirection="column" paddingTop={2} key={opcoes.id}>
                  <Box component="div">
                    <img src={opcoes.icone} alt={opcoes.nome} />
                  </Box>
                </Box>
              ))
            ) : (
              <Box>&nbsp;</Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BarraLateral;
