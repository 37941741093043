import React from 'react';
import Box from '@material-ui/core/Box';
import Iframe from 'react-iframe';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iframe: { border: 0 },
}));

export default function MainGrid(props) {
  const classes = useStyles;

  return (
    <Iframe
      url={`${process.env.REACT_APP_AXERWEB}${props.url}`}
      width="100%"
      height={window.innerHeight}
      id="iFramePrincipal"
      className={classes.iframe}
      display="initial"
      position="relative"
      frameBorder={0}
    />
  );
}
