export const chktoken = (token) => {
  var jwt = require('jsonwebtoken');
  var jwtDecodificado = jwt.decode(token);
  if (jwtDecodificado === null) {
    return false;
  }
  var idUsuario = jwtDecodificado[0];
  var empresaCliente = jwtDecodificado[1] * 1;
  var bdCliente = jwtDecodificado[2];
  var expiracaoCliente = jwtDecodificado[3];
  var Agora = new Date();
  var valido = true;

  if (idUsuario === 0 || empresaCliente === 0) {
    valido = false;
  }

  function pad2(n) {
    return n < 10 ? '0' + n : n;
  }

  var sAgora =
    Agora.getFullYear().toString() +
    pad2(Agora.getMonth() + 1) +
    pad2(Agora.getDate()) +
    pad2(Agora.getHours()) +
    pad2(Agora.getMinutes()) +
    pad2(Agora.getSeconds());

  if (sAgora >= expiracaoCliente || expiracaoCliente === '' || expiracaoCliente === null) {
    valido = false;
  }

  if (!bdCliente.includes('mbr')) {
    valido = false;
  }

  return valido;
};
