import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from 'react-router';

export default function Configuracoes() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const logoff = () => {
    localStorage.clear();
    history.push('/');
  };

  return (
    <div>
      <SettingsIcon
        color="disabled"
        fontSize="default"
        aria-controls="configuracoes-menu"
        aria-haspopup="true"
        onClick={handleClickMenu}
      />
      <Menu
        id="configuracoes-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleCloseMenu}>Configurações do Sistema</MenuItem>
        <MenuItem onClick={handleCloseMenu}>Configurações do Usuário</MenuItem>
        <hr />
        <MenuItem onClick={logoff}>Sair</MenuItem>
      </Menu>
    </div>
  );
}
