import React from 'react';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import SearchIcon from '@material-ui/icons/Search';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Configuracoes from '../../paginas/main/Configuracoes';

const useStyles = makeStyles((theme) => ({
  icones: {
    fontColor: theme.palette.grey[500],
  },
  dataAtual: {
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#000',
    overflow: 'hidden',
  },
  nomeEmpresa: {
    fontWeight: 'bold',
    fontSize: '13px',
    color: '#000',
    overflow: `hidden`,
    marginLeft: '5px',
  },
  justifyStart: {
    marginRight: `auto`,
  },
}));

const BarraSuperior = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dataAtual = new Date();
  const dataFormatada = format(dataAtual, "iiii',' dd 'de' MMMM 'de' yyyy '-' HH':'mm'hs'", {
    locale: ptBR,
  });

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      overflow="hidden"
      style={{ backgroundColor: theme.palette.background.paper }}
    >
      <Box
        display="flex"
        justifyContent="flex-start"
        className={`${classes.nomeEmpresa} ${classes.justifyStart}`}
      >
        Sindicato dos Bancários
      </Box>
      <Box component="div" className={`${classes.dataAtual}`} paddingX={2}>
        <Typography variant="subtitle2">{dataFormatada}</Typography>
      </Box>
      <Box>
        <InputBase placeholder="Busca..." inputProps={{ 'aria-label': 'Busca...' }} />
        <IconButton className="debug" type="submit" aria-label="search">
          <SearchIcon />
        </IconButton>
      </Box>
      <Box paddingX={1}>
        <Badge badgeContent={1} color="error">
          <DateRangeOutlinedIcon color="disabled" fontSize="default" className={classes.icones} />
        </Badge>
      </Box>
      <Box paddingX={1}>
        <Badge badgeContent={4} color="error">
          <QuestionAnswerOutlinedIcon
            color="disabled"
            fontSize="default"
            className={classes.icones}
          />
        </Badge>
      </Box>
      <Box paddingX={1}>
        <Badge badgeContent={8} color="error">
          <MailOutlineIcon color="disabled" fontSize="default" className={classes.icones} />
        </Badge>
      </Box>
      <Box paddingX={1}>
        {/* <Link
          to="/"
          onClick={() => {
            localStorage.clear();
          }}
        >
          <SettingsIcon color="disabled" fontSize="default" className={classes.icones} />
        </Link> */}
        <Configuracoes />
      </Box>
    </Box>
  );
};

export default BarraSuperior;
