import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Rotas from './componentes/nucleo/Rotas';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

const axerweb = createMuiTheme({
  overrides: {
    MuiTabs: {
      root: {
        marginTop: '-8px',
      },
      indicator: {
        display: 'none',
      },
    },
    MuiTab: {
      root: {
        fontSize: '12px',
        fontWeight: 'bold',
        marginTop: '15px',
        backgroundColor: '#E6E6E6',
        marginRight: '5px',
        borderRadius: '5px',
        paddingBottom: '15px',
        width: '120px',
        '&$selected': {
          backgroundColor: '#FFF',
        },
      },
    },
  },
  palette: {
    common: { black: '#000', white: '#fff' },
    background: { paper: '#fff', default: '#fafafa' },
    primary: {
      light: 'rgba(73, 144, 77, 0.5)',
      main: 'rgba(73, 144, 77, 1)',
      dark: 'rgba(65, 117, 5, 1)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(33, 50, 66, 0.66)',
      main: 'rgba(33, 50, 66, 1)',
      dark: 'rgba(13, 20, 26, 1)',
      contrastText: '#fff',
    },
    error: { light: '#e57373', main: '#f44336', dark: '#d32f2f', contrastText: '#fff' },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
});

ReactDOM.render(
  // BrowserRouter na raiz do App pq permite
  // ele possa usar o histórico do navegador
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <MuiThemeProvider theme={axerweb}>
      {/* Executa a função de rotas para que ele
      decida para onde o usuário está indo */}
      <Rotas />
    </MuiThemeProvider>
  </BrowserRouter>,
  // Plota na raiz do CSS
  document.getElementById('root'),
);
// Habilitar para PWA (Service Workers): https://bit.ly/CRA-PWA
serviceWorker.unregister();
