import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Alert from '@material-ui/lab/Alert';
// import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import estaAutenticado from '../../componentes/nucleo/Auth';
import { useForm } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';
import logoLateral from '../../assets/imagens/logo_awerweb_horiz_branca.svg';
import logoMob from '../../assets/imagens/logo_mob.svg';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '30px',
  },
  form: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    padding: '10px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  primeiroQuadro: {
    [theme.breakpoints.up('xs')]: {
      backgroundColor: theme.palette.secondary.main,
      background: `url(${logoMob}) no-repeat center`,
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.secondary.main,
      background: `url(${logoLateral}) no-repeat center`,
    },
  },
  segundoQuadro: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    alignItems: 'center',
  },
  tituloForm: {
    fontSize: '20px',
  },
  terceiroQuadro: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFF',
  },
  imagemResponsiva: {
    width: '100%',
    height: 'auto',
  },
}));

export default function Login() {
  const telaCelular = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { register, handleSubmit, getValues, errors } = useForm();
  const [emailUsuario, setemailUsuario] = useState('');
  const [senhaUsuario, setsenhaUsuario] = useState('');
  const [msgErro, setmsgErro] = useState('');
  const cookies = new Cookies();
  const [empresa, setEmpresa] = useState(cookies.get('empresa'));

  const checaLogin = () => {
    axios
      .post('https://acesso4.webgerente.com.br/wg3/api/index.php', null, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          funcao: 'login',
          email: emailUsuario,
          senha: senhaUsuario,
        },
      })
      .then((response) => {
        if (!response.data.token) {
          setmsgErro('LOGIN INVÁLIDO!');
        } else {
          const decoded = jwtDecode(response.data.token);
          localStorage.setItem('token', response.data.token);
          cookies.set('empresa', decoded[1]);
          window.location.reload(false);
        }
      })
      .catch((erro) => {
        setmsgErro(erro);
      });
  };

  useEffect(() => {
    if (empresa !== 0) {
      setEmpresa(cookies.get('empresa'));
    }
  }, [cookies, empresa]);

  if (estaAutenticado() === true) {
    return <Redirect to="/main" />;
  }

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems={telaCelular ? 'flex-start' : 'center'}
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <CssBaseline />
      {/* O BLOCO ABAIXO É PARA VISUALIZAÇÃO
      NO DESKTOP APENAS (DISPLAY sm) */}
      <Box
        display="flex"
        minHeight={telaCelular ? '630px' : '400px'}
        maxHeight={telaCelular ? '630px' : '400px'}
        minWidth={telaCelular ? '350px' : '1000px'}
        maxWidth={telaCelular ? '350px' : '1000px'}
        flexDirection={telaCelular ? 'column' : 'row'}
        justifyContent={telaCelular ? 'flex-start' : 'center'}
        alignItems="center"
      >
        <Box
          component="div"
          minHeight={telaCelular ? '60px' : '300px'}
          maxHeight={telaCelular ? '60px' : '300px'}
          minWidth={telaCelular ? '300px' : '100px'}
          maxWidth={telaCelular ? '300px' : '100px'}
          className={classes.primeiroQuadro}
        >
          &nbsp;
        </Box>
        <Box
          component="div"
          minHeight={telaCelular ? '390px' : '400px'}
          maxHeight={telaCelular ? '390px' : '400px'}
          minWidth={telaCelular ? '350px' : '400px'}
          maxWidth={telaCelular ? '350px' : '400px'}
          boxShadow={3}
          className={classes.segundoQuadro}
        >
          <Box paddingLeft="40px" paddingTop="12px">
            <h5 className={classes.tituloForm}>Entrar</h5>
          </Box>
          <Box marginLeft={0.5} padding={1}>
            <form margin={1} className={classes.form} onSubmit={handleSubmit(checaLogin)}>
              <TextField
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                margin="normal"
                inputRef={register({
                  required: true,
                  // pattern: {
                  //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  //   message: 'Endereço de e-mail inválido',
                  // },
                })}
                onChange={(e) => setemailUsuario(e.target.value)}
                value={emailUsuario}
                fullWidth
                id="email"
                label="Endereço de e-mail"
                name="email"
                autoComplete="email"
                autoFocus
              />
              {errors.email && <Alert severity="error">{errors.email.message}</Alert>}
              <TextField
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                margin="normal"
                inputRef={register({ required: true })}
                onChange={(e) => setsenhaUsuario(e.target.value)}
                value={senhaUsuario}
                fullWidth
                name="senha"
                label="Senha"
                type="password"
                id="senha"
                autoComplete="current-password"
              />
              {msgErro && (
                <Alert severity="error">
                  Ocorreu um erro na autenticação, confira login e senha e tente novamente!
                </Alert>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Entrar
              </Button>
            </form>
          </Box>
          <Box
            minHeight="80px"
            maxHeight="80px"
            minWidth="125px"
            maxWidth="125px"
            textAlign="center"
            paddingTop={0}
          >
            {empresa > 0 && (
              <img
                name="logo"
                src={`https://acesso4.webgerente.com.br/magazinebr2/empresa${empresa}/logo.jpg`}
                alt="AxerWeb Sistemas"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    'https://acesso4.webgerente.com.br/magazinebr2/empresa001/logo.jpg';
                }}
                className={classes.imagemResponsiva}
              />
            )}
          </Box>
        </Box>
        <Box
          minHeight={telaCelular ? '190px' : '300px'}
          maxHeight={telaCelular ? '190px' : '300px'}
          minWidth={telaCelular ? '300px' : '400px'}
          maxWidth={telaCelular ? '300px' : '400px'}
          component="div"
          padding={1}
          className={classes.terceiroQuadro}
        >
          <pre style={{ color: '#FFF', padding: '10px' }}>
            {JSON.stringify(getValues(), null, 1)}
          </pre>
        </Box>
      </Box>
      {/* DEBUG */}
      {/* <DevTool control={control} /> */}
    </Grid>
  );
}
